// We import our styles here, webpack will do the rest
import styles from '../scss/app.scss'

// lazy sizes for lazy loading images
import 'picturefill'

// Our own helpers
import './helpers/objectfitFallback'
import './helpers/iframe'

// Our own components
import './components/primaryNavigation'
import './components/accessibility';

/**
 *
 *  COPIED OVER FROM MAASEUTU
 *
 */

import jquery from 'jquery';
window.jQuery = jquery;
window.$ = jquery;
import _ from 'lodash';

import './jquery.bxslider-rahisified/jquery.bxslider-rahisified'

// == Events == //

$(window).resize(function () {
  infoboxPosition();
});

$(document).ready(function () {
  infoboxPosition();
  stickyBar();
  placeHolderPolyfill();
  bxSlider();
  // navigationToggle();

  var allLinks = document.querySelectorAll('a');
  for (var i = 0; i < allLinks.length; i++) {
    var isLinkToDoc = allLinks[i].getAttribute('href').match(/(doc|pdf|xls)/);
    if (isLinkToDoc) {
      allLinks[i].setAttribute('aria-label', linkToDocText);
    }
  }
});

// == Functions == //


// Placeholder polyfill for legacy browsers
var placeHolderPolyfill = function () {
  if ($('input[type=text]').length > 0 && document.createElement("input").placeholder === undefined) {
    $('input[type=text]').each(function () {
      if ($(this).val() === '' && $(this).attr("placeholder") !== '') {
        $(this).val($(this).attr("placeholder"));
        $(this).focus(function () {
          if ($(this).val() === $(this).attr("placeholder")) $(this).val('');
        });
        $(this).blur(function () {
          if ($(this).val() === '') $(this).val($(this).attr("placeholder"));
        });
      }
    });
  }
};

// Infobox moves around dom depending on the breakpoint
var infoboxPosition = function () {
  if ($("div.infobox-block").hasClass("edit-mode")) {
    return;
  }
  var breakpoint = 992;
  var boxInUpperPart = $(".right-contentarea div.infobox-block").length > 0;
  var boxInLowerPart = $(".info-box-jump-holder div.infobox-block").length > 0;
  var width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  if (width < breakpoint && boxInUpperPart) {
    $(".right-contentarea div.infobox-block").detach().prependTo(".info-box-jump-holder");
  }
  else if (width >= breakpoint && boxInLowerPart) {
    $(".info-box-jump-holder div.infobox-block").detach().prependTo(".right-contentarea");
  }
};

// Sticky Bar functionality
var stickyBar = function () {
  if (typeof $('#sticky-bar').scrollToFixed !== 'undefined') {
    $("#sticky-bar").scrollToFixed({
      preFixed: function () { $('#sticky-bar .to-top').css('display', 'table-cell'); },
      postFixed: function () { $('#sticky-bar .to-top').hide(); }
    });
  }
};

// Create mobile menu
$.fn.mobilemenu = function (t) {
  // == Get menu markup with ajax  == //
  $.ajax({
    url: '/api/menu/markup/' + t.language,
    dataType: 'html'
  })
    .done(function (data) {
      $(data).appendTo('.panels');
      // Once ajax has been loaded, add click event
      $('.menu-icon').click(function () {
        openMenu();
      });
    })
    .fail(function () {
      console.log("Mobile menu cannot be created.");
    });

  // == Open menu  == //
  var openMenu = function () {
    // Add open class to wrapper and show menu and remove loader
    $(".site-navigation-mobile").addClass('open');
    $('.menu-icon').hide();
    $('.menu-panel').removeClass('active');
    // Set selected
    var r = $(".menu-panel").find("li[data-id=" + t.currentPageId + "]");
    if (r.length > 0) {
      r.addClass('selected');
      r.parents('.menu-panel').addClass('active');
    } else {
      $(".panels").children('.menu-panel').first().addClass('active');
    }
    // Create breacrumbs
    createCrumbs();
    // Show menu
    $(".mobilemenu").show();
  };


  // == Breadcrumb creator == //
  var createCrumbs = function () {
    // Reset and empty
    $('.crumbs').html('');
    // Find if panel has previous button, if not do not continue
    var previous = $('.menu-panel.active').find('.prev');
    if (previous.length > 0) {
      var currentMenuName = previous.data('parent-name');
      var target = previous.data('target');
      // loop through breadcrumb items
      crumbCreator(currentMenuName, target);
    }
  };

  var crumbCreator = function (currentMenuName, target) {
    // Get container and prepend element
    var container = $('.menubar .crumbs');
    var element = $('<span>' + currentMenuName + '</span>');
    container.prepend(element);
    // // Get new target and run function again if menu has previous button (has parents)
    // var targetMenu = $('.menu-panel[data-id=' + target + ']');
    // var newMenuName = targetMenu.find('.prev').data('parent-name');
    // if(newMenuName !== undefined) {
    //    var newTarget = targetMenu.find('.prev').data('target');
    //    crumbCreator(newMenuName, newTarget);
    // }
  };

  // == Next and previous button event listeners == //
  $(document).on('click', '.next, .prev', function () {
    // Get target menu to be opened
    var target = $(this).data('target');
    // Current active menu
    var current = $(".menu-panel.active").data('id');
    // If the target and current are different, move to target menu
    if (target !== current) {
      // Hide and show
      $('.menu-panel[data-id=' + current + ']').removeClass('active');
      $('.menu-panel[data-id=' + target + ']').addClass('active');
      // Create breacrumbs
      createCrumbs();
    }
  });

  // == Close menu button event listener == //
  $(document).on('click', '.close-menu', function () {
    $(".mobilemenu").hide();
    $(".site-navigation-mobile").removeClass('open');
    $('.menu-icon').show();
  });
};

var bxSlider = function () {
  $('.slides-container').each(function (ix) {
    var that = $(this);
    that.bxSlider({
      minSlides: 1,
      maxSlides: 4,
      responsive: true,
      adaptiveHeight: true,
      infiniteLoop: false,
      hideControlOnEnd: true,
      // slideSelector: '#slider-' + ix + ' .slide',
      autoReload: true,
      pager: false,
      moveSlides: 1,
      onSliderLoad: function (currentIndex) {
        var sliderCount = this.slides;
        for (var i = currentIndex; i < sliderCount; i++) {
          $(that[0]).find('.slide-' + i).addClass('active');
        }
      },
      onSlideAfter: function ($slideElement, oldIndex, newIndex) {
        var sliderCount = this.slides;
        var slide;
        if (oldIndex < newIndex) { // Moving forward
          slide = that[0].querySelector('.slide-' + (oldIndex));
        } else if (oldIndex > newIndex) { // Moving backward
          slide = that[0].querySelector('.slide-' + (oldIndex + sliderCount - 1));
        }
        if (slide) slide.classList.remove('active');
        for (var i = newIndex; i < (newIndex + sliderCount); i++) {
          that[0].querySelector('.slide-' + i).classList.add('active');
        }
      },
      breaks: [{
        screen: 0,
        slides: 1
      },
      {
        screen: 768,
        slides: 2
      },
      {
        screen: 992,
        slides: 4
      }
      ]
    });
  });
};