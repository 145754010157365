function closeNavBar () {
	var navBar = document.querySelector('#navbar');
	var navToggleButton = document.querySelector('[aria-controls="navbar"]');

	navBar.classList.remove('in');
	navBar.setAttribute('aria-expanded', 'false');
	navToggleButton.setAttribute('aria-expanded', 'false')
	navBar.classList.add('collapsed');
	navToggleButton.focus();
}

document.addEventListener('keyup', function(event) {
	var el = event.target;
	var key = event.key;
	var megamenuAll = document.querySelectorAll('.megamenu');
	var megamenuIndex = 0;
	var navBar = document.querySelector('#navbar');
	var isFocusInMegamenu = false;
	var isNavbarOpen = false;
	for(var i=0; i < megamenuAll.length; i++) {
		if(!isFocusInMegamenu) {
			isFocusInMegamenu = megamenuAll[i].contains(el);
			megamenuIndex = i;
		}
	}
	isNavbarOpen = navBar.contains(el);

	if(isNavbarOpen) {
		if (key == 'Escape') closeNavBar();
	}
	if(isFocusInMegamenu) {
		var selectedKey = key.toUpperCase();
		var selector = '[data-letter-index="' + selectedKey +'"]';
		var selectedList = megamenuAll[megamenuIndex].querySelector(selector);
		if(!!selectedList) {
			selectedList.querySelector('.letter-line a').focus();
		}
	}
}, false);